
export function splicingTimeSection(day, startTime, endTime){
  return day + " " + startTime + "-" + endTime;
}

export function splicingTime(day, startTime){
  return day + " " + startTime;
}

/**
 * 判断是否为空 为空返回true
 * @param obj
 * @returns {boolean}
 */
export function isEmpty(obj){
  if(typeof(obj) == "undefined" || obj == null || obj === ""){
    return true;
  }else{
    return false;
  }
}

export function include(content, search) {
  if (content.indexOf(search) != -1) {
    return true;
  } else {
    return false;
  }
}
