/**
 * 访问其他系统的API
 */

import {request, simpleRequest} from "@/network/request";
import {isEmpty} from "@/utils/stringUtils";

/**
 * 登录方法
 * @param router  路由
 * @param message  消息提示
 * @param loginForm  登录需提交数据
 * @param systemApiInfo  系统访问信息
 * @param loginType  登录类别
 */
export function login(router, message, systemApiInfo, tableFrom, loginType) {

  let loginForm = JSON.parse(JSON.stringify(tableFrom));
  delete loginForm.mobile;
  if (loginType === 'account') { // 账号密码登录
    loginForm['loginApiUrl'] = systemApiInfo.upApiUrl;
    simpleRequest({
      url: systemApiInfo.loginUrl,
      method: 'post',
      data: loginForm
    }).then(res => {
      let data = res.data;
      if (data.success) {
        let token = data.map.token;
        if (!isEmpty(loginForm.systemType)){
          let url = systemApiInfo.loginOkUrl + "?SESSION=" + token + "&systemType="+ loginForm.systemType;
          if (data.code === 201){
            window.location.href = data.map.redirectUrl;
          } else {
            window.location.href = url;
          }
        } else {
          window.location.href = systemApiInfo.loginOkUrl + "?SESSION=" + token;
        }
      } else {
        let msg = data.msg;
        message.error(msg);
        if (msg.search("未认证")  != -1 ) {
          router.push({name: 'authentication',
            params:{
              username: loginForm.username }}
          );
        }

      }
    }).catch(err => {
      message.error('网络错误,请稍后再试');
    });
  } else if (loginType == 'message') {  // 手机号登录

    loginForm['loginApiUrl'] = systemApiInfo.smsApiUrl;
    simpleRequest({
      url: systemApiInfo.smsLoginUrl,
      method: 'post',
      data: loginForm
    }).then(res => {
      let data = res.data;
      if (data.success) {
        let token = data.map.token;
        if (!isEmpty(loginForm.systemType)){
          let url = systemApiInfo.loginOkUrl + "?SESSION=" + token + "&systemType="+ loginForm.systemType;
          if (data.code === 201){
            window.location.href = data.map.redirectUrl;
          } else {
            window.location.href = url;
          }
        } else {
          window.location.href = systemApiInfo.loginOkUrl + "?SESSION=" + token;
        }
      } else {
        let msg = data.msg;
        message.error(msg);
        if (msg.search("未认证")  != -1) {
          router.push({name: 'authentication',
            params:{
              username: loginForm.username }}
          );
        }

      }
    }).catch(err => {
      message.error('网络错误,请稍后再试');
    });
  } else {

  }
}

export function newLogin(router, message, systemApiInfo, tableFrom, loginType) {
  let loginForm = JSON.parse(JSON.stringify(tableFrom));
  loginForm['loginType'] = loginType;
  loginForm['systemInfoIID'] = systemApiInfo.id;
  request({
    url: '/thirdParty/login',
    method: 'post',
    data: loginForm
  }).then(res => {
    let data = res.data;
    if (data.success) {
      window.location.href = data.successUrl;
    } else {
      let msg = data.msg;
      message.error(msg);
      if (msg.search("未认证")  != -1) {
        router.push({name: 'authentication',
          params:{
            username: loginForm.username }}
        );
      }
    }
  })
}




