<template>

  <div id="mainBody">

    <div id="headBox">
      <img :src="headImg" class="headImg">
    </div>
    <div class="textPrompt">
      <!--      <div></div>-->
      <span class="text1">永利八达通系统登录</span> <br>
      <span class="text2">传承·改变·务实·创新</span>

    </div>

    <div id="loginBox">

      <div class="loginBox1">

      </div>

      <div class="loginBox2">

        <div class="formBox">
          <div class="lg-label">
            <h4>用户登录</h4>
          </div>
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="账号登录" name="account"></el-tab-pane>
              <el-tab-pane label="短信登录" name="message"></el-tab-pane>
            </el-tabs>
          </div>
          <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="100px" class="loginForm">
            <div v-if="isShow">
              <el-form-item prop="username">
                <el-input v-model.trim="loginForm.username" prefix-icon="el-icon-user" @change="toUpCase"
                          placeholder="用户名"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model.trim="loginForm.password" prefix-icon="el-icon-lock" show-password
                          placeholder="请输入密码"></el-input>
              </el-form-item>
            </div>
            <div v-else>
              <el-form-item prop="mobile">
                <el-input v-model.trim="loginForm.mobile" prefix-icon="el-icon-user" placeholder="手机号"></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input v-model.trim="loginForm.code" id="codeInput" prefix-icon="el-icon-lock"
                          placeholder="请输入验证码">
                  <template slot="append">
                    <el-button size="mini" @click="sendMsg" :disabled="msgBut">{{ countdownShow }}</el-button>
                  </template>
                </el-input>
              </el-form-item>
            </div>

            <el-form-item>
              <el-select v-model="systemInfo"
                         value-key="systemType"
                         @change="infoChange"
                         placeholder="请选择需要登录的系统">
                <el-option
                  v-for="item in systems"
                  :key="item.systemType"
                  :label="item.loginName"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="subBut" round @click="submitForm('loginForm')">登录</el-button>
            </el-form-item>
          </el-form>

          <div>
            <router-link to="/authentication" class="loginA">注册认证</router-link>
            <router-link to="/updatePassword" class="loginA">忘记密码</router-link>
          </div>


        </div>


      </div>

    </div>

  </div>

</template>

<script>
import {isEmpty} from "@/utils/stringUtils";
import {checkAuthStatus, getUniversalInfo, oneSign, sendMessage} from "@/network/obtainAdminInfo";
import {login, newLogin} from "@/network/accessOtherApi";

export default {
  name: "LoginView",
  data() {
    return {
      headImg: require("@/assets/img/bdt_logo_white.png"),
      loginForm: {
        username: '',
        password: '',
        code: '',
        mobile: ''
      },
      rules: {
        username: [{required: true, message: '请输入用户名', trigger: 'blur'}],
        password: [{required: true, message: '密码不能为空', trigger: 'blur'}],
        code: [{required: true, message: '验证码不能为空', trigger: 'blur'}],
        mobile: [{required: true, message: '手机号不能为空', trigger: 'blur'},
          {pattern: /^1[23456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur'}
        ],
      },
      activeName: 'account',
      isShow: true,
      timing: 0,
      msgBut: false,
      systems: [],
      systemInfo: {},
      dialogFormVisible: false
    }
  },
  computed: {
    countdownShow() {
      return this.timing == 0 ? '发送验证码' : this.timing + '秒后重试';
    }
  },
  methods: {
    toUpCase() {
      this.loginForm.username = this.loginForm.username.toUpperCase();
    },
    handleClick(tab, event) {  // 切换登录方式
      if (this.activeName == 'account') {
        this.isShow = true;
        this.loginForm.username = '';
        this.loginForm.mobile = '';
        this.loginForm.password = '';
        this.loginForm.code = '';
      } else {
        this.isShow = false;
        this.loginForm.username = '';
        this.loginForm.mobile = '';
        this.loginForm.password = '';
        this.loginForm.code = '';
      }
    },
    countdown(e) {  // 倒计时方法
      let timer = null
      timer = setInterval(() => {
        e--
        e < 0 ? clearInterval(timer) : this.timing = e;
      }, 1000)
    },
    sendMsg() {   // 发送短信方法
      if (this.timing > 0) {
        this.msgBut = true;
        return;
      } else {
        this.msgBut = false;
      }
      if (isEmpty(this.loginForm.mobile)) {
        this.$message.error('手机号不能为空');
        return;
      }

      sendMessage(this.$message, this.loginForm.mobile);

      this.countdown(60);
    },
    submitForm(formName) {  // 登录提交方法
      console.log('bbbbbb')
      if (isEmpty(this.systemInfo.systemType)) {
        this.$message.error('请选择需要登录的系统');
        return;
      }

      if (this.systemInfo.systemType === 'oms') {
        this.loginForm.systemType = 1;
      }

      if (this.systemInfo.systemType === 'newoms') {
        this.loginForm.systemType = 0;
      }


      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!isEmpty(this.loginForm.mobile)) {
            this.loginForm.username = this.loginForm.mobile;
          }

          /*checkAuthStatus(this.loginForm.username).then(res => {
            console.log(res);
            if (res.code == 201) {
              console.log("特殊处理逻辑")
              let data = res.data;
              let id = data.id;
              let name = data.userName;
              let mobile = isEmpty(data.agentMobile) ? data.mobile : data.agentMobile;

              // 尊敬的***用户，您的账号已实名认证但未签署走货合同。请点击“发送“获取在线合同，
              // 合同接收手机号为：”135****1255“，请留意短信并根据链接步骤签署合同。
              // 我司将于10月15日关闭未签署合同用户登录通道。
              const h = this.$createElement;
              this.$alert('<div><strong>' +
                '<h3>尊敬的' + name + '用户</h3>' +
                '<span>您的账号已实名认证但未签署走货合同。请根据需求点击下方按钮获取在线合同，' +
                '合同接收手机号为：' + mobile + '，请留意短信并根据链接步骤签署合同。我司将于10月20日关闭未签署合同用户登录通道。</span>' +
                '</strong></div>', '温馨提示', {
                dangerouslyUseHTMLString: true,
                showCancelButton: true,
                confirmButtonText: '发送并登录',
                cancelButtonText: '暂不发送登录',
              }).then(() => {
                oneSign(this.$message, id);
                newLogin(this.$router, this.$message, this.systemInfo, this.loginForm, this.activeName);
              }).catch(() => {
                newLogin(this.$router, this.$message, this.systemInfo, this.loginForm, this.activeName);
              });
            } else {
              newLogin(this.$router, this.$message, this.systemInfo, this.loginForm, this.activeName);
            }
          })*/

          // login(this.$router, this.$message, this.systemInfo, this.loginForm, this.activeName);
          newLogin(this.$router, this.$message, this.systemInfo, this.loginForm, this.activeName);
        } else {
          return false;
        }
      })

    },
    omsLogin(systemType, formName) {
      this.loginForm.systemType = systemType;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!isEmpty(this.loginForm.mobile)) {
            this.loginForm.username = this.loginForm.mobile;
          }
          login(this.$router, this.$message, this.systemInfo, this.loginForm, this.activeName);
        } else {
          return false;
        }
      })
    },
    infoChange(value) {
      let systemType = value.systemType;
      let loginUrl = value.loginUrl;
      if (systemType === 'toms') {
        window.location.href = loginUrl;
      }
      return true;
    }

  },
  created() {
    getUniversalInfo(this.$message).then(res => {
      if (!isEmpty(res)) {
        this.systems = res;
      }
    });

  },
  mounted() {
   /* let msg = `<strong>
     <h3>尊敬的客户:</h3>
     <p>&nbsp&nbsp&nbsp&nbsp我司将于今日18:30-19:30进行系统维护，期间国际快递系统及FBA系统无法登录，造成不便敬请谅解，如有疑问请与销售员联系。</p>
     </strong>`;
    // let colorMsg = `<strong>
    //  <h3 style="color: #E6A23C">尊敬的客户:</h3>
    //  <p style="color: #F56C6C">&nbsp&nbsp&nbsp&nbsp我司将于今日18:30-19:30进行系统维护，期间国际快递系统及FBA系统无法登录，造成不便敬请谅解，如有疑问请与销售员联系。</p>
    //  </strong>`;
    this.$alert(msg, '系统通知', {
      dangerouslyUseHTMLString: true
    });*/
  }

}

</script>

<style scoped>

#mainBody {
  width: 100%;
  height: 100%;
  position: relative;
  min-width: 948px;
  min-height: 745px;
  margin: 0px;
  padding: 0px;
  background-image: linear-gradient(to right, #018eed, #40c79f);

}

#headBox {
  width: 100%;
  height: 8%;
  position: relative;
}


#mainBody.loginBox {
  display: flex;
  align-items: center;
  justify-content: center;
}


#loginBox {
  width: 890px;
  height: 458px;
  left: calc(50% - 447px);
  top: calc(50% - 370px);
  /*width: 60%;*/
  /*height: 60%;*/
  /*left: 18%;*/
  /*top: 2%;*/
  position: relative;
  background: url("~@/assets/img/login_background.png") no-repeat;
  background-size: 100%;

  /* 从这开始下面都是横竖居中属性 */
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/

}

.loginBox1 {
  width: 50%;
  height: 100%;
  position: relative;
  float: left;
  /*background: url("~@/assets/img/embellished1.png") no-repeat right;*/
  background-image: url("~@/assets/img/embellished1.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  /*background-size: 60%;*/
}

.loginBox2 {
  /*width: 50%;*/
  /*height: 100%;*/
  width: 333px;
  height: 450px;
  top: 13px;
  left: 20px;
  position: relative;
  float: left;
  text-align: center;
}

.loginA {
  float: left;
  width: 50%;
  height: 33px;
  text-align: center;
  line-height: 30px;
  text-decoration-line: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}


.formBox {
  /*top: 15%;*/
  /*left: 5%;*/
  /*width: 70%;*/
  /*height: 80%;*/
  display: contents;
  float: left;
  position: relative;
  /*border-radius: 30px;*/
  box-shadow: 2px 2px 5px #000;
}

.formBox /deep/ .el-tabs__nav {
  width: 100%;
}

.formBox /deep/ .el-tabs__nav > div {
  width: 50%;
  padding: 0px;
}

/deep/ .el-form-item__content {
  width: 80% !important;
  margin: auto !important;
}

.el-select {
  width: 100%;
}


.el-form {
  float: left;
  top: 3%;
}

.headImg {
  height: 60%;
  width: 190px;
  padding-left: 20px;
  margin-top: 5px;
  left: 20%;
}

.textPrompt {
  width: 100%;
  height: 10%;
  text-align: center;
  position: relative;
}

.text1 {
  font-size: 40px;
  letter-spacing: 6px;
  color: #FFFFFF;
}

.text2 {
  /*font-size: 40px;*/
  margin: 20px 0;
  letter-spacing: 10px;
  color: #FFF;
}

.formBox >>> .el-input__inner {

  /*border-top: 0;*/
  /*border-left: 0;*/
  /*border-right: 0;*/
  border-width: 0 0 2px 0;

}

.lg-label {
  font-size: 22px;
  height: 44px;
  line-height: 44px;
  margin-top: 23px;
  text-indent: 30px;
  margin-bottom: 20px;
  text-align: left;
  border-left: 4px solid #4190f6;
  color: #4190f6;
}


.loginForm {
  width: 100%;
  display: block;
}

.subBut {
  width: 50%;
}


a {
  font-size: 12px;
}


</style>
