import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'babel-polyfill'

import {
         Button, Input, Form, FormItem, Tabs, TabPane,
        Steps, Step, Card, Message, Row, Col, Divider,
        Upload, Radio, RadioGroup, Dialog, Select, Option, Loading, MessageBox,Checkbox
} from 'element-ui'

Vue.config.productionTip = false

Vue.use(Button);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Card);
Vue.use(Row);
Vue.use(Col);
Vue.use(Divider);
Vue.use(Upload);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
