import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  //数据，相当于data
  state: {
    systemType: '8dt'
  },
  getters: {

  },
  //里面定义方法，操作state方发
  mutations: {

    updateSystemType(state, systemType){
      console.log('vuex使用: '+systemType);
      state.systemType = systemType;
    }

  },
  // 操作异步操作mutation
  actions: {

  },
  modules: {

  },
})
