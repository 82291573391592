/**
 * 访问后台接口
 */

import {request} from "@/network/request";
import {sign} from "@/utils/md5Utils";
import {include, isEmpty} from "@/utils/stringUtils";
import store from "@/store"



export function getCompanyInfo() {

  let data = [{'id':1, 'value':'8dt', 'label':'深圳永利八达通物流科技有限公司'},
    {'id':2, 'value':'sh8dt', 'label':'上海永利八达通物流科技有限公司'}];

  return data;
}

/**
 * 获取 SystemInfo 开始
 * @param message
 * @returns {Promise<*>}
 */
export async function getUniversalInfo(message) {

  let returnData;

  await request({
    url: '/sys-system-info/getUniversalInfo',
    method: "get",
  }).then(res => {
    let data = res.data;
    if (data.code == 200) {
      returnData = data.data;
    } else {
      message.error(data.msg);
    }
  }).catch(err => {
    message.error(err);
  })

  return returnData;
}

/**
 * 获取销售员信息
 * @param message
 * @returns {Promise<*>}
 */
export async function getSalesmanInfo(message) {

  let returnData;

  await request({
    url: '/open/salesman/findSalesmanAll',
    method: "get",
  }).then(res => {
    let data = res.data;
    if (data.code == 200) {
      returnData = data.data;
    } else {
      message.error(data.msg);
    }
  }).catch(err => {
    message.error(err);
  })

  return returnData;
}

/**
 * 获取省份信息
 * @param message
 * @returns {Promise<*>}
 */
export async function getProvinceInfo(message) {

  let returnData;

  await request({
    url: '/open/pcdInfo/province/findAll',
    method: "get",
  }).then(res => {
    let data = res.data;
    if (data.code == 200) {
      returnData = data.data;
    } else {
      message.error(data.msg);
    }
  }).catch(err => {
    message.error(err);
  })

  return returnData;
}

/**
 * 获取城市信息
 * @param message
 * @param pid
 * @returns {Promise<*>}
 */
export async function getCityListInfo(message, pid) {

  let returnData;

  await request({
    url: '/open/pcdInfo/city/findByPid/'+pid,
    method: "get",
  }).then(res => {
    let data = res.data;
    if (data.code == 200) {
      returnData = data.data;
    } else {
      message.error(data.msg);
    }
  }).catch(err => {
    message.error(err);
  })

  return returnData;
}


/**
 * 根据城市ID查询区域
 * @param message
 * @param pid
 * @returns {Promise<*>}
 */
export async function getAreaListInfo(message, pid) {

  let returnData;

  await request({
    url: '/open/pcdInfo/area/findByCityId/'+pid,
    method: "get",
  }).then(res => {
    let data = res.data;
    if (data.code == 200) {
      returnData = data.data;
    } else {
      message.error(data.msg);
    }
  }).catch(err => {
    message.error(err);
  })

  return returnData;
}

/**
 * 发送短信验证码
 * @param message
 * @param mobile
 */
export function sendMessage(message, mobile) {

  let sign1 = sign(mobile);

  request({
    url: '/code/sms?mobile=' + mobile,
    method: "get",
    headers: {
      'sign': sign1
    }
  }).then(res => {
    if (res.status == 200 && isEmpty(res.data)) {
      message.success('发送成功, 请等待');
    } else {
      message.error("发送失败:" + res.data.msg);
    }
  }).catch(err => {
    message.error("网络异常,请稍后重试: " + err);
  });

}

export let checkUsername = (rule, value, callback) => {


  request({
    url: '/people/checkUsername/' + value,
    method: 'get'
  }).then(res => {
    let data = res.data;
    if (data.code == 200) {
      callback();
    } else {
      callback(new Error(data.msg));
    }
  }).catch(err => {
    callback(new Error('网络异常,检验不成功' + err));
  });

}

export let checkMobile = (rule, value, callback) => {


  request({
    url: '/people/checkMobile/' + value,
    method: 'get'
  }).then(res => {
    let data = res.data;
    if (data.code == 200) {
      callback();
    } else {
      callback(new Error(data.msg));
    }
  }).catch(err => {
    callback(new Error('网络异常,检验不成功' + err));
  });

}


export let checkEmail = (rule, value, callback) => {

  request({
    url: '/people/checkEmail/' + value,
    method: 'get'
  }).then(res => {
    let data = res.data;
    if (data.code == 200) {
      callback();
    } else {
      callback(new Error(data.msg));
    }
  }).catch(err => {
    callback(new Error('网络异常,检验不成功' + err));
  });

}


export function checkTelecom3Factors(rule, value, callback, from) {
  if (!isEmpty(from.name) && !isEmpty(from.idNo) && !isEmpty(from.mobile)) {
    request({
      url: '/verify/individual/verifyBase',
      method: 'post',
      headers: {
        'sysType': store.state.systemType
      },
      data: {'idNo': from.idNo, 'name': from.name, 'mobileNo': from.mobile}
    }).then(res => {
      let data = res.data;
      if (data.code != 200) {
        if (include(data.msg, '信息比对不通过')) {
          callback(new Error("请检查姓名,手机号, 身份证号是否正确(需要本人真实信息)"));
        } else {
          callback(new Error(data.msg));
        }

      } else {
        callback();
      }
    }).catch(err => {
      callback(new Error(err));
    })

  }

}


export function checkAgentTelecom3Factors(rule, value, callback, from) {

  if (!isEmpty(from.agentName) && !isEmpty(from.agentIdNo) && !isEmpty(from.agentMobile)) {
    request({
      url: '/verify/individual/verifyBase',
      method: 'post',
      headers: {
        'sysType': store.state.systemType
      },
      data: {'idNo': from.agentIdNo, 'name': from.agentName, 'mobileNo': from.agentMobile}
    }).then(res => {
      // console.log(res.data);
      let data = res.data;
      if (data.code != 200) {
        if (include(data.msg, '信息比对不通过')) {
          callback(new Error("请检查姓名,手机号, 身份证号是否正确(需要本人真实信息)"));
        } else {
          callback(new Error(data.msg));
        }
      } else {
        callback();
      }
    }).catch(err => {
      callback(new Error(err));
    })

  }

}

/**
 * 检测客户是否已发送未签署
 * @param username
 */
export async function checkAuthStatus(username) {
  let data = null;
  await request({
    url: '/people/checkAuthStatus/' + username,
    method: 'get'
  }).then(res => {
    data = res.data;
  }).catch(err => {
    // console.log("检测客户是否已发送未签署异常:"+ err);
  });

  return data;
}

export function oneSign(message, peopleID) {
  request({
    url: '/people/oneSign/' + peopleID,
    method: 'get'
  }).then(res => {
    // console.log(res);
    if (res.status == 200) {
      message.success('发送成功, 请注意查收短信');
    } else {
      message.error("发送失败, 请稍后重试:" + res.data.msg);
    }
  }).catch(err => {
    message.error("发送失败,请稍后重试: " + err);
  });
}


export async function checkSmsCode(from) {
  let flag = false;
  let phone = from.mobile;
  if(from.isAgent == 1) {
    phone = from.agentMobile;
  }
  await request({
    url: '/common/checkSmsCode',
    method: 'post',
    data: {'mobile': phone, 'code': from.code}
  }).then(res => {
    flag = true;
  }).catch(err => {
    flag = false;
  })

  return flag;
}


/**
 * 检查用户名是否可用
 * @param username
 * @param message
 * @returns {Promise<boolean>}
 */
export async function checkUserName(username, message) {
  let flag = false;
  await request({
    url: '/people/checkUsername/' + username,
    method: 'get'
  }).then(res => {
    let data = res.data;
    if (data.code == 200) {
      flag = true;
    } else {
      flag = false;
    }
  }).catch(err => {
    message.error("服务器繁忙,请稍后重试: " + err);
  });

  return flag;
}

// export function checkSmsCode(rule, value, callback, from) {
//   if (!isEmpty(from.mobile) && !isEmpty(from.code)) {
//     request({
//       url: '/common/checkSmsCode',
//       method: 'post',
//       data: {'mobile': from.mobile, 'code': from.code}
//     }).then(res => {
//       console.log(res);
//       let data = res.data;
//       if (data.code != 200) {
//         callback(new Error(data.msg));
//       } else {
//         callback();
//       }
//     }).catch(err => {
//       callback(new Error(err.response.data));
//     })
//   }
// }


export function verifyOrganization(rule, value, callback, from) {

  if (!isEmpty(from.name) && !isEmpty(from.organCode) && !isEmpty(from.fullName)) {

    request({
      url: '/verify/individual/verifyOrganize',
      method: 'post',
      headers: {
        'sysType': store.state.systemType
      },
      data: {
        'name': from.fullName,
        'orgCode': from.organCode,
        'legalRepName': from.name
      }
    }).then(res => {
      let data = res.data;
      if (data.code != 200) {
        if (include(data.msg, '信息比对不通过')) {
          callback(new Error("法人姓名, 公司全称, 社会信用代码须和营业执照完全一致"));
        } else {
          callback(new Error(data.msg));
        }
      } else {
        callback();
      }
    }).catch(err => {
      callback(new Error(err));
    })

  }
}


export async function whetherAuthByMobile(mobile) {
  let flag = false;
  await request({
    url: '/api/whetherAuthByMobile/' + mobile,
    method: 'GET'
  }).then(res => {
    let data = res.data.data;
    if (data.authStatus == 0) {
      flag = true;
    }
  }).catch(err => {
    flag = true;
  })
  return flag;
}


// const peopleFrom = {
//   userName: '',
//   passWord: '',
//   nikeName: '',
//   mobile: '',
//   idNo: '',
//   email: '',
//   name: '',
//   fullName: '',
//   organCode: '',
//   agentName: '',
//   agentIdNo: '',
//   agentMobile: '',
//   account: '',
//   accountName: '',
//   bankName: '',
//   openBank: '',
//   isAgent: 0,
//   userType: 0,
//   frontFile: '',
//   backFile: '',
//   license: '',
//   agentFrontFile: '',
//   agentBackFile: '',
//   sysType: '8dt',
//   salesman: '',
//   social: ''
// };

export async function getPeopleInfo(from, username) {

  let sign1 = sign(username);

  await request({
    url: '/people/sign/getPeopleInfo/' + username,
    method: "get",
    headers: {
      'sign': sign1
    }
  }).then(res => {
    let people = res.data.data;
    if (!isEmpty(people)) {
      from.userName = people.userName.toUpperCase();
      from.passWord = people.passWord
      from.nikeName = people.nikeName
      from.mobile = people.mobile
      from.idNo = people.idNo
      from.email = people.email
      from.hiddenMobile = people.hiddenMobile
      // from.name = people.name
      // from.fullName = people.fullName
      from.organCode = people.organCode
      from.agentName = people.agentName
      from.agentIdNo = people.agentIdNo
      from.agentMobile = people.agentMobile
      from.account = people.account
      from.accountName = people.accountName
      from.bankName = people.bankName
      from.openBank = people.openBank
      from.salesman = people.salesman
      from.isAgent = people.isAgent
      from.address = people.address
      from.social = people.social
    }

  }).catch(err => {
    console.log(err);
  });
  console.log(from);

}


