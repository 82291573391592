import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from "@/views/LoginView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: {
      title: '登录'
    }
  }
  , {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Register'),
    meta: {
      title: '注册'
    }
  }
  , {
    path: '/authentication',
    name: 'authentication',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Authentication'),
    meta: {
      title: '实名认证'
    }
  }
  , {
    path: '/success',
    name: 'success',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Success'),
    meta: {
      title: '成功'
    }
  }, {
    path: '/tip',
    name: 'tip',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Tip'),
    meta: {
      title: '提示'
    }
  }
  , {
    path: '/updatePassword',
    name: 'updatePassword',
    component: () => import(/* webpackChunkName: "about" */ '@/views/UpdatePassword'),
    meta: {
      title: '修改密码'
    }
  }
  , {
    path: '/bindOld',
    name: 'bindOld',
    component: () => import('@/views/BindOld'),
    meta: {
      title: '客户绑定'
    }
  }
  , {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/Callback'),
    meta: {
      title: '提示'
    }
  }
  , {
    path: '/displayInfo',
    name: 'displayInfo',
    component: () => import('@/views/DisplayInfo'),
    meta: {
      title: '政策声明'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }

  if(navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger'){ //判断如果是微信浏览器
    let path='';
    if(to.path==='/tip'){ //若是微信浏览器的提示页
      Vue.prototype.flag=true;
      next();
    }else{
      Vue.prototype.flag = false;
      path='/tip';
      next(path);
    }
  }else{ //非微信浏览器
    if (to.path==='/tip') {
      next('/');
    } else {
      next();
    }

  }

})



export default router
