import axios from "axios";

export function loginRequest(config, baseUrl) {
  // 1. 创建 axios 的实例
  const instance = axios.create({
    baseURL: baseUrl,
    timeout: 180000,
    withCredentials: true
  });
  return instance(config);
}


export function baseUrlRequest(config, baseUrl) {
  // 1. 创建 axios 的实例
  const instance = axios.create({
    baseURL: baseUrl,
    timeout: 180000
  });
  return instance(config);
}

export function simpleRequest(config){
  // 1. 创建 axios 的实例
  const instance = axios.create({
    timeout: 180000
  });

  return instance(config);
}


/**
 * 正式环境
 * @param config
 * @returns {AxiosPromise}
 */
export function request(config) {

  let baseURL = 'http://htapi.8dt.com';
  let isHttps = 'https:' == document.location.protocol ? true: false;
  if(isHttps){
    baseURL = 'https://htapi.8dt.com';
  }

  // 1. 创建 axios 的实例
  const instance = axios.create({
    baseURL: baseURL,
    timeout: 180000
  });

  return instance(config);
}

/**
 * 测试环境
 * @param config
 * @returns {AxiosPromise}
 */
/*export function request(config) {
  // 1. 创建 axios 的实例
  const instance = axios.create({
    // baseURL: 'http://cdmapi.8dt.com',
    baseURL: 'http://127.0.0.1:8819',
    // baseURL: 'https://htapi.8dt.com',
    // baseURL: 'http://htapi.8dt.com',
    // baseURL: baseURL,
    timeout: 180000
  });

  return instance(config);
}*/
